import React from "react";
import styled from "styled-components";
import logo from "../assets/images/TAV-logo.png";


const NotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color:${props => props.theme.colors.primary};

  img {
    max-width: 30%;
    height: auto;
    margin-bottom: 20px;
  }

  h1 {
    font-size: 40px;
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    color: #555;
  }
`;

const NotFound = () => {
  
  return (
    <NotFoundContainer>
      <img src={logo} alt="Logo" />
      <h1>404 - Not Found</h1>
      <p>Lost baggage? Nah, just a lost page. </p>
    </NotFoundContainer>
  );
};

export default NotFound;
