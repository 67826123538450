import React, { useState, useEffect } from "react";
import i18n from '../i18n';
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Button from "./Button";
import FormSteps from "./FormSteps";
import Container from "./Container";
import Icon from "./Icon";
import checkmark from "../assets/icons/checkmark.svg";
import RegisterForm from "./RegisterForm";  
import { useTranslation } from 'react-i18next';



const StyledTitle = styled.div`
  font-size: 1.25rem;
  color: ${props => props.theme.colors.primary};
  font-weight: 100;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: grid;
  height: 5rem;
  @media (min-width: 768px) {
    font-size:1.75rem;
  }
`;

const BoldText = styled.div`
  font-weight: bold;
`;

const StyledLanguageButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 5rem;
`;

const StyledLanguageButton = styled.div`
  position: relative;
  text-align: center;
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  padding: 12px;
  padding-top: 20px;
  outline: none;
  cursor: pointer;
  color: ${props => props.$isSelected ? props.theme.colors.primary : `${props.theme.colors.primary}50`};

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
  @media (min-width: 768px) {
    font-size:1rem;
  }
`;


const Checkmark = styled.img`
  position: absolute;
  top: 20px;
  right: 10px;
`;

const Mobile = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;
const DesktopOnly = styled.div`
  @media screen and (min-width: 767px) {
    display: none;
  }
`;

const Divider = styled.div`
  @media screen and (min-width: 768px) {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;



const LanguageSelector = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();


  const [titles, setTitles] = useState([
    "PLEASE SELECT YOUR PREFERRED LANGUAGE",
    "ВЕ МОЛИМЕ ИЗБЕРЕТЕ ЈАЗИК КОЈ ГО ПРЕФЕРИРАТЕ",
    "JU LUTEM ZGJIDHNI GJUHËN TUAJ E PREFERUAR",
  ]);

  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [showRegisterForm, setShowRegisterForm] = useState(false);  
  const [showLanguageSelector, setShowLanguageSelector] = useState(true);


  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % titles.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [titles]);

  const chooseLanguage = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
  
  };

  const handleNextClick = () => {
    if (selectedLanguage) {
      setShowRegisterForm(true);
      setShowLanguageSelector(false);
    }
  };

  const getStyledTitle = () => {
    const wordsToBold = [
      ["LANGUAGE"],
      ["ЈАЗИК"],
      ["GJUHËN"],
    ];

    const titleWords = titles[currentTitleIndex].split(' ');

    return titleWords.map((word, index) => {
      const isBold = wordsToBold.some((boldWords) => boldWords.includes(word));
      return isBold ? <BoldText key={index}>{word}</BoldText> : word + " ";
    });
  };

  return (
    <Divider>
      <Mobile>
        {showRegisterForm ? <RegisterForm /> : <FormSteps first />}
      </Mobile>
      {showLanguageSelector && (
        <>
      <StyledTitle>{getStyledTitle()}</StyledTitle>
      <StyledLanguageButtons>
        <StyledLanguageButton
          onClick={() => chooseLanguage('en')}
          $isSelected={selectedLanguage === 'en'}
        >
          {t('ENGLISH')}
          {selectedLanguage === 'en' && <Checkmark src={checkmark} />}
        </StyledLanguageButton>
        <StyledLanguageButton
          onClick={() => chooseLanguage('mk')}
          $isSelected={selectedLanguage === 'mk'}
        >
         {t('MACEDONIAN')}
          {selectedLanguage === 'mk' && <Checkmark src={checkmark} />}
        </StyledLanguageButton>
        <StyledLanguageButton
          onClick={() => chooseLanguage('al')}
          $isSelected={selectedLanguage === 'al'}
        >
          {t('ALBANIAN')}
          {selectedLanguage === 'al' && <Checkmark src={checkmark} />}
        </StyledLanguageButton>
      </StyledLanguageButtons>
      <DesktopOnly>
      
        <Button fullWidth onClick={handleNextClick} disabled={!selectedLanguage}>
          {t('submitButton')}
        </Button>
 
      </DesktopOnly>
      </>
      )}
    </Divider>
  );
};

export default LanguageSelector;
