import React from 'react';
import styled, { css } from 'styled-components';
import Icon from './Icon';
import wifiIcon from '../assets/icons/icon-wifi.png';

const Square = styled.div`
  width: 20px;
  height: 20px;
  padding: 2px;
  background-color: ${props => (props.active ? props.theme.colors.primary : `${props.theme.colors.primary}30`)};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: ${props => (props.active ? '600' : '100')};



  &.first {
    background-color: ${props => props.theme.colors.primary}; 
  }

  &.second {
    background-color: ${props => props.theme.colors.primary}; 
  }

  &.third{
    background-color: ${props => props.theme.colors.primary}; 

  }
`;

const Steps = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 3rem;
`;

const Dash = styled.div`
  height: 3px;
  width: 40px;
  background-color: ${props => props.theme.colors.primary}30;
  margin-left: 10px;
  margin-right: 10px;
`;

const FormSteps = ({ first, second , third }) => {
  return (
    <Steps className="mobile-only">
      <Square className={first && 'first'}>
        1
      </Square>
      <Dash />
      <Square  className={second && 'second'}>
        2
      </Square>
      <Dash />
      <Square className={third && 'third'}>
        <Icon src={wifiIcon} />
      </Square>
    </Steps>
  );
};

export default FormSteps;
