import React from 'react';
import styled from 'styled-components';
import { theme } from '../styles/theme';
import logo from "../assets/images/TAV-logo.png";
import HomeIcon from "../assets/icons/home.svg";
import { Link } from "react-router-dom";
import LanguageSwitcherDropdown from './LanguageSwitcherDropdown';
import { useTranslation } from 'react-i18next';


const StyledHeader = styled.div`
margin-bottom:2rem;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items:center;
   @media screen and (max-width: 768px) {
    justify-content:center;
  }
`;

const Logo = styled.img`
  height: auto;

  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (min-width: 768px) {
    width: 30%;
  }
`;

const LeftComponent = styled.div`

  
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const RightComponent = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const MenuItem = styled(Link)`
display:flex;
flex-direction:row;
align-items:center;
  color: ${props => props.theme.colors.primary};
  font-size:1.25rem;

`;


function Header({ children }) {
  const { t } = useTranslation();
  return (
    <StyledHeader>
      <LeftComponent> 
      <MenuItem to="/welcome"><img style={{ marginRight: '5px' }} src={HomeIcon}/>{t('home')}</MenuItem>
      </LeftComponent>

      <Logo src={logo} alt="Logo" />

      <RightComponent><LanguageSwitcherDropdown/></RightComponent>

      {children}
    </StyledHeader>
  );
}

export default Header;
