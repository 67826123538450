import React, { createContext, useContext, useState, useEffect } from 'react';

const SessionContext = createContext(null);

export const useSession = () => useContext(SessionContext);

export const SessionProvider = ({ children }) => {
  const [session, setSession] = useState(localStorage.getItem('userEmail'));

  useEffect(() => {
    const clearSession = () => localStorage.removeItem('userEmail');
    window.addEventListener('beforeunload', clearSession);
    return () => window.removeEventListener('beforeunload', clearSession);
  }, []);

  const updateSession = (email) => {
    localStorage.setItem('userEmail', email);
    setSession(email);
  };

  const clearSessionManually = () => {
    localStorage.removeItem('userEmail');
    setSession(null);
  };

  return (
    <SessionContext.Provider value={{ session, updateSession, clearSessionManually }}>
      {children}
    </SessionContext.Provider>
  );
};
