import axios from 'axios';
import { DASHBOARD_SETTINGS_GET_TEXT } from './endpoints';
import { sprintf } from 'sprintf-js';

const dashboardSettings = {
   
    get: (key) => axios.get(DASHBOARD_SETTINGS_GET_TEXT.replace('%(key)s', key)),

  
};

export default dashboardSettings;