import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { listParkingPrices, listHugAndFlyPrices } from '../services/carParkService';
import InfoText from './InfoText';
import info from "../assets/icons/info.svg";
import Icon from "../components/Icon";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  @media screen and (min-width: 767px) {
    height: 48vh;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  height: 21vh;
  overflow: auto;
  @media screen and (min-width: 767px) {
    height: 50vh;
  }
`;

const InfoContainer = styled.div``;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  color: ${(props) => props.theme.colors.primary};
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

const StyledTh = styled.th`
  font-weight: bold;
  padding: 8px;
  border: 1px solid #ddd;
  text-align: left;
`;

const StyledTd = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
`;

const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const OptionContainer = styled.div`
  width: 100%;
  background-color: ${(props) => (props.$active ? props.theme.colors.primary : props.theme.colors.primary + '20')};
  color: ${(props) => (props.$active ? 'white' : props.theme.colors.primary)};
  padding: 2px 0;
  cursor: pointer;
  text-align: center;
  &.flexOption {
    flex: 0.48;
  }
  &.thirdOption {
    flex: 1;
  }
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
  ${(props) =>
    props.$active &&
    css`
      box-shadow: 0px 4px 4px 0px #00000040;
      background-color: ${props.theme.colors.primary};
      color: white;
    `}
`;

const Text = styled.a`
  color: ${(props) => props.theme.colors.primary};
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

const MoreInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const CarParkTable = () => {
  const { t, i18n } = useTranslation();
  const [selectedTable, setSelectedTable] = useState('Parking');
  const [parkingData, setParkingData] = useState([]);
  const [hugAndFlyData, setHugAndFlyData] = useState([]);
  const currentLanguage = i18n.language;

  useEffect(() => {
    listParkingPrices(currentLanguage)
      .then(response => {
        setParkingData(response.data);
      })
      .catch(error => console.error(error));

    listHugAndFlyPrices(currentLanguage)
      .then(response => {
        setHugAndFlyData(response.data);
      })
      .catch(error => console.error(error));
  }, [currentLanguage]);

  const handleButtonClick = (table) => {
    setSelectedTable(table);
  };

  const renderInfo = () => {
    switch (selectedTable) {
      case 'Parking':
        return (
          <>
            <InfoText keyName={`parking`} />
            <MoreInfo>
              <Icon src={info} />
              <Text href="https://skp.airports.com.mk/mk-MK/park-mk">{t('moreInfoCarPark')}</Text>
            </MoreInfo>
          </>
        );

      case 'Hug&Fly':
        return (
          <>
            <InfoText keyName={`hug_and_fly_${currentLanguage}`} />
            <MoreInfo>
              <Icon src={info} />
              <Text href="https://skp.airports.com.mk/mk-MK/park-mk">{t('moreInfoCarPark')}</Text>
            </MoreInfo>
          </>
        );

      default:
        return null;
    }
  };

  const renderTable = () => {
    let tableData = [];
    switch (selectedTable) {
      case 'Parking':
        tableData = parkingData;
        const additionalDayData = tableData.filter(item => item.quantity === 'Each additional day');
        const otherData = tableData.filter(item => item.quantity !== 'Each additional day');
        const sortedTableData = [...otherData, ...additionalDayData];
        return (
          <StyledTable>
            <thead>
              <tr>
                <StyledTh>{t('time')}</StyledTh>
                <StyledTh>{t('price')}</StyledTh>
              </tr>
            </thead>
            <tbody>
              {sortedTableData.map((item, index) => (
                <tr key={index}>
                  <StyledTd>
                    {item.quantity === 'Each additional day' ? t('eachAdditionalDay') : `${item.quantity} ${item.quantity_type.time}`}
                  </StyledTd>
                  <StyledTd>{item.price} {t('valuta')}</StyledTd>
                </tr>
              ))}

            </tbody>
          </StyledTable>
        );
      case 'Hug&Fly':
        tableData = hugAndFlyData;
        return (
          <StyledTable>
            <thead>
              <tr>
                <StyledTh>{t('time')}</StyledTh>
                <StyledTh>{t('price')}</StyledTh>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item, index) => (
                <tr key={index}>
                  <StyledTd>{item.quantity} {item.time} {item.quantity_type.time}</StyledTd>
                  <StyledTd>{item.price} {t('valuta')}</StyledTd>
                </tr>
              ))}
            </tbody>
          </StyledTable>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Container>
        <RowContainer>
          <OptionContainer
            className='flexOption'
            $active={selectedTable === 'Parking'}
            onClick={() => handleButtonClick('Parking')}
          >
            {t('parking')}
          </OptionContainer>
          <OptionContainer
            className='flexOption'
            $active={selectedTable === 'Hug&Fly'}
            onClick={() => handleButtonClick('Hug&Fly')}
          >
            {t('hugAndFly')}
          </OptionContainer>
        </RowContainer>

        <TableContainer>{renderTable()}</TableContainer>
      </Container>
      <InfoContainer>{renderInfo()}</InfoContainer>
    </>
  );
};

export default CarParkTable;
