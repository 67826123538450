import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DeviceContext } from '../contexts/DeviceContext';
import { useSession } from '../contexts/SessionContext';
import { Navigate } from 'react-router-dom';

import Container from '../components/Container';
import Icon from '../components/Icon';
import Button from '../components/Button'

import backgroundImg from '../assets/images/landingImg-min.png';
import banner from '../assets/images/baner.png';
import wifiIcon from '../assets/icons/icon-wifi.png';

import UrlParamsContext from "../contexts/URLparamsContext"

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImg});
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  position: fixed;
  display: flex;
  flex-direction: column;
`;

const BannerImage = styled.img`
  width: 100%;
  height: 15vh;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  align-items: flex-start;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 6.25rem;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 4.5rem;
  }

  @media screen and (min-width: 1200px) {
    font-size: 5rem;
  }
`;

const Description = styled.div`
  font-weight: 100;
  font-size: 3rem;
  padding-right: 0.5rem;

  @media screen and (max-width: 768px) {
    font-size: 1.75rem;
  }

  @media screen and (min-width: 768px) {
    font-size:2.5rem;
  }

  @media screen and (min-width: 1200px) {
    font-size: 3rem;
  }
`;

const FlexBlock = styled.div`
display:flex;
padding-top:2%;
padding-bottom:2%;
align-items:center;`

const StyledLink = styled(Link)`
  text-decoration: none;
`;

function Home() {
  const { setUrlParams } = useContext(UrlParamsContext);
  const { isMobile } = useContext(DeviceContext);
  const { session } = useSession();


 

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(queryParams.entries());
        setUrlParams(params);
      
    }, [setUrlParams]);
    if (session) {
      // User is logged in, redirect to welcome
      return <Navigate to="/welcome" />;
    }

  return (
    <Background>
     {isMobile ? (
              <iframe src='https://ads.skpairport.mk/www/delivery/afr.php?zoneid=9'    style={{ width: '100%', height: '130px',border:"none",overflow:"hidden" }}/>
            ) : (
                
                <iframe src="https://ads.skpairport.mk/www/delivery/afr.php?zoneid=1"    style={{  width: '100%',border:"none",overflow:"hidden", scrolling:"no"}}/>
            )}
      <Container>
        <ContentWrapper>
          <Title>WELCOME TO <br /> SKOPJE AIRPORT</Title>
          <FlexBlock>
            <Description>GET <strong>FREE WI-FI</strong> NOW</Description>
            <Icon src={wifiIcon} alt="Icon" />
          </FlexBlock>
          <StyledLink to="/login">
            <Button>CONNECT NOW</Button>
          </StyledLink>
        </ContentWrapper>
      </Container>
    </Background>
  );
}

export default Home;

