import React,{useEffect} from 'react';
import styled from 'styled-components';

import HomeContainer from '../components/HomeContainer';
import Title from '../components/Title';
import TitleIcon from "../assets/icons/about.svg";
import { useHotspotUserInfo } from '../utils/useHotspotUserInfo';
import Popup from '../components/Popup';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';


const Text = styled.p`
color:${props => props.theme.colors.primary};
line-height:24px;
font-weight:200;;
font-size:1rem;
@media screen and (min-width: 768px) {
  font-size:1.25rem;
  line-height:30px;
}`

const Container=styled.div`
color:${props => props.theme.colors.primary};
@media screen and (max-width: 768px) {
  height: 45vh; // Set the desired height
  overflow: auto;
}
height: 60vh; // Set the desired height
overflow: auto;
`



function TermsAndConditions() {
  const { showPopupUser } = useHotspotUserInfo();
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  console.log(lang);
  useEffect(() => {
    if (lang && i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  return (
    <HomeContainer >
      <Title icon={TitleIcon} text="termsAndConditions2" />
     <Container>
     <Trans i18nKey="termsOfUse" components={{ strong: <strong />, br: <br />,li:<li/> }} />
      </Container> 
      {showPopupUser && <Popup />}
    </HomeContainer>
  );
}

export default TermsAndConditions;

