import React, { useContext, useState, useEffect } from 'react';
import RegisterForm from '../components/RegisterForm';
import LanguageSelector from '../components/LanguageSelector';
import styled from 'styled-components';
import Container from '../components/Container';
import { DeviceContext } from '../contexts/DeviceContext';

import banner from '../assets/images/electronic-devices-balancing-concept.jpg';


const Desktop = styled.div`
  display: flex;
  padding-left:15rem;
  align-items: center;
  padding-right: 15rem;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const VerticalLine = styled.div`
  border: 2px solid #ccc;
  margin: 50px;
  height: 70vh;
`;

const Mobile = styled.div`
  display: none;
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;


const ModalContent = styled.div`


  width: 80%;
  max-width: 600px;
  max-height: 80%;
  overflow: auto;
  position: relative;
  padding-top: 20px; 
`;

const CloseButton = styled.span`
  position: absolute;
  top: -10px; 
  right:0;
  cursor: pointer;
  font-size: 1.5rem;
  color: #fff; 

  padding: 5px;

`;


function Login() {
  const [showPopup, setShowPopup] = useState(true);
  const { isMobile } = useContext(DeviceContext);

  useEffect(() => {
    setShowPopup(true);
  }, []);

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <Container>
      {showPopup && (
        <ModalOverlay onClick={closePopup}>
          <ModalContent onClick={e => e.stopPropagation()}>
            <CloseButton onClick={closePopup}>X</CloseButton>
            <iframe
              src={isMobile ? 'https://ads.skpairport.mk/www/delivery/afr.php?zoneid=10' : 'https://ads.skpairport.mk/www/delivery/afr.php?zoneid=2'}
              style={{ width: '100%', height: '310px', border: 'none' }}
              title="Ad"
            />
          </ModalContent>
        </ModalOverlay>
      )}
      <Mobile>
        <LanguageSelector />
      </Mobile>
      <Desktop>
        <LanguageSelector />
        <VerticalLine />
        <RegisterForm />
      </Desktop>
    </Container>
  );
}

export default Login;
