import React from 'react';
import styled from 'styled-components';



const CustomInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
 
`;

const CustomInputField = styled.input`
  border: none;
  font-size:1rem;

  border-bottom: 1px solid ${props => props.theme.colors.primary};
  padding: 12px;
  outline: none;
  color:${props => props.theme.colors.primary};

  &:focus {
    border-bottom: 2px solid ${props => props.theme.colors.primary};
  }

  &::placeholder {
    color:${props => props.theme.colors.primary}50;
  }
  @media (min-width: 768px) {
    font-size:1rem;
  }

`;
const ErrorMessage=styled.div`
color:rgba(203, 130, 130, 0.5);`

const CustomInput = ({ placeholder, value, onChange, onBlur, error }) => {
  return (
    <>
    <CustomInputContainer>
      <CustomInputField
        type="email"
        name="email" // Add this line
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
     
    </CustomInputContainer>
     {error && <ErrorMessage>{error}</ErrorMessage>}
     </>
  );
};

export default CustomInput;


