import axios from 'axios';
import { MAP_ENDPOINT } from './endpoints';
import { sprintf } from 'sprintf-js';

export const getMap = async (type_name) => {
  try {
    const endpoint = sprintf(MAP_ENDPOINT, { type_name });
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching car operators:', error);
    throw error;
  }
};
