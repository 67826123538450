import axios from 'axios';
import {BUS_GET_TEXT } from './endpoints';
import { sprintf } from 'sprintf-js';

const busSettings = {
   
    get: (key) => axios.get(BUS_GET_TEXT.replace('%(key)s', key)),

  
};

export default busSettings;