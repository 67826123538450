import React, { createContext, useState } from 'react';

export const UserTypeContext = createContext({
  userType: null,
  setUserType: () => {}
});

export const UserTypeProvider = ({ children }) => {
    const [userType, setUserType] = useState(null);

    return (
        <UserTypeContext.Provider value={{ userType, setUserType }}>
            {children}
        </UserTypeContext.Provider>
    );
};
