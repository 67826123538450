import React from 'react';
import styled from 'styled-components';
import checkmark from "../assets/icons/checkmark.svg";
import { useTranslation } from 'react-i18next';


const CheckboxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  color:${props => props.theme.colors.primary};
  padding-top:25px;
  padding-bottom:25px;
  @media (min-width: 768px) {
    font-size:1rem;
  }
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

const StyledCheckbox = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid ${props => props.theme.colors.primary};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CheckmarkIcon = styled.img`
  width: 80%;
  height: 80%;
  object-fit: cover; /* Ensure the icon covers the entire container */
  display: ${props => (props.checked ? 'block' : 'none')};
`;

const NonLinkLabel = styled.span`
  margin-right: 4px;
  margin-left: 4px;
`;

const Link = styled.a`
  text-decoration: underline;
  color: ${props => props.theme.colors.primary};
`;

const Checkbox = ({ checked, onChange, linkTo ,language}) => {
  const { t } = useTranslation();

  return (
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} onChange={onChange} />
      <StyledCheckbox>
        <CheckmarkIcon
          checked={checked}
          src={checkmark} 
          alt="Checkmark Icon"
        />
      </StyledCheckbox>
      <NonLinkLabel>{t('termsAndConditionsLabel')} </NonLinkLabel>
      <Link href={`/terms-and-conditions/${language}`} target="_blank">{t('termsAndConditions')}</Link>
    </CheckboxContainer>
  );
};

export default Checkbox;
