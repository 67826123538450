import axios from 'axios';
import { HOTSPOT_USER_ENDPOINT ,HOTSPOT_USER_REMAINING_ENDPOINT} from './endpoints';


export const registerHotspotUser = async (userData) => {
  return axios.put(HOTSPOT_USER_ENDPOINT, userData);
    
  }

  export const getInfoForHotspotUser = async (mac) => {
    try {
      const formattedEndpoint = HOTSPOT_USER_REMAINING_ENDPOINT.replace('%(mac)s', mac);
      const response = await axios.get(formattedEndpoint);
      return response.data;
    } catch (error) {
      console.error('Error fetching info for user:', error);
      throw error;
    }
  };
  