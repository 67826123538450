import React from 'react';
import styled from 'styled-components';
import { theme } from '../styles/theme';
import logo from "../assets/images/TAV-logo.png";

const StyledContainer = styled.div`
   margin: 2.5rem 1.5rem 2.5rem 1.5rem;
   display:flex;
   flex-direction:column;
  flex:1;
   position: relative; 
   justify-content:space-between;
`;

const Logo = styled.img`
  display:flex;
  align-self:center;
  height: auto;

  @media screen and (max-width: 768px) {
    width:80%; /* Test with a simple change for a specific width */
  }
  @media screen and (min-width: 768px) {
    width:30%; /* Test with a simple change for a specific width */
  }
`;



function Container({ children, noLogo }) {
  return (
    <StyledContainer>
      {!noLogo && <Logo src={logo} alt="Logo" />}
      {children}
    </StyledContainer>
  );
}

export default Container;
