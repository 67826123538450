import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { listTaxiRoutes } from '../services/taxiService'; 
import InfoText from './InfoText';


const SearchContainer = styled.div`
  position: relative;
  border-bottom: 1px solid black;
`;

const Input = styled.input`
border: none;
font-size:1rem;
width:93%;

padding: 12px;
outline: none;
text-align:center;
color:${props => props.theme.colors.primary};

&:focus {
  border-bottom: 2px solid ${props => props.theme.colors.primary};
}

&::placeholder {
  color:${props => props.theme.colors.primary}70;
}
@media (min-width: 768px) {
  font-size:1.5rem;
}
  
`;

const OptionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  max-height: 200px;
  overflow-y: auto;
  display: ${(props) => (props.$show ? 'block' : 'none')};
  font-size:1rem;
  color:${props => props.theme.colors.primary};
  @media (min-width: 768px) {
    font-size:1.5rem;
  }

`;

const Option = styled.li`
  padding: 10px;
  cursor: pointer;

  &:nth-child(even) {
    background-color: #e0e0e0;
  }

  &:nth-child(odd) {
    background-color:  #f2f2f2;}

  &:hover {
    background-color:${props => props.theme.colors.primary}70;
  }
`;


const ResultContainer = styled.div`
  margin-top: 10px;
  @media (min-width: 768px) {
  height: 45vh; 
  overflow: auto;
  }
`;
const Icon = styled.img`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const Desktiption=styled.div`
font-size:1rem;
color:${props => props.theme.colors.primary};
@media (min-width: 768px) {
  font-size:1.5rem;

}
`

const Description=styled.div`
font-size:1rem;
color:rgba(203, 130, 130, 0.5);
@media (min-width: 768px) {
  font-size:1.5rem;
}
`

const Contact=styled.div`
display:flex;
flex-direction:column;
margin-top:2rem;
font-size:1rem;
color:${props => props.theme.colors.primary};

@media (min-width: 768px) {
  font-size:1.5rem;
}
`
const Mail=styled.a`
text-decoration:none;
color:${props => props.theme.colors.primary};

&:hover {
  text-decoration:underline;
}
`

const ContactSpan=styled.div`
font-weight:600;
color:${props => props.theme.colors.primary};`

const Search = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [destinations, setDestinations] = useState([]); 
  const [showOptions, setShowOptions] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [hasSearched, setHasSearched] = useState(false);
  const wrapperRef = useRef(null);
  const { i18n } = useTranslation();
  const {t}=useTranslation();
  
  const currentLanguage = i18n.language;

  useEffect(() => {
    
      listTaxiRoutes(currentLanguage)
        .then(response => {
          setDestinations(response.data); // Assuming response contains the data directly
        })
        .catch(error => {
          console.error('Error fetching taxi routes:', error);
        });
    



    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
     
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [currentLanguage]);

  const handleInputChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setShowOptions(query.length > 0); 
    setSearchError(false);
    setHasSearched(true);
  };

  const handleOptionClick = (destination) => {
    setSearchQuery(destination.destination); 
    setSelectedDestination(destination);
    setShowOptions(false);
    setHasSearched(true);
  };

  useEffect(() => {
    const isMatch = destinations.some(destination =>
      destination.destination.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchError(searchQuery.length > 0 && !isMatch);
  }, [searchQuery, destinations]);

  return (
    <>
      <SearchContainer ref={wrapperRef}>
        <Input
          type="text"
          placeholder={t('searchPlaceholder')}
          value={searchQuery}
          onChange={handleInputChange}
          onClick={() => setShowOptions(true)}
        />
        <Icon src={require('../assets/icons/search.svg').default} alt="Search Icon" />

        <OptionsList $show={showOptions}>
          {destinations
            .filter((destination) => destination.destination.toLowerCase().includes(searchQuery.toLowerCase()))
            .map((destination) => (
              <Option key={destination.id} onClick={() => handleOptionClick(destination)}>
                {destination.destination}
              </Option>
            ))}
        </OptionsList>
      </SearchContainer>

      {!hasSearched ? (
        <ResultContainer>
          <Desktiption>{t('defaultSearchText')}</Desktiption>
          <Contact>
            <ContactSpan>{t('contact')}</ContactSpan>
            <span>{t('email-taxi')} <Mail href="mailto:elita-el@hotmail.com">elita-el@hotmail.com</Mail></span>
            <span>{t('phone-taxi')} +389 70 813 037</span>
            <span>{t('work-hours')} {t("while-landing")}</span>
          </Contact>
        </ResultContainer>
      ) : selectedDestination ? (
        <ResultContainer>
        
          <Desktiption><strong>{t('fixedPriceLabel')}</strong> {selectedDestination.price} {t('currency')}</Desktiption>
          <Desktiption><strong>{t('distanceLabel')}</strong> {selectedDestination.distance} {selectedDestination.distance_unit.unit}</Desktiption>

          <InfoText keyName="taxi"/>
        </ResultContainer>
      ) : searchError ? (
        <ResultContainer>
          <Description>{t("searchError")}</Description>
        </ResultContainer>
      ) : null}
    </>
  );
};

export default Search;
