import React from 'react';
import styled from 'styled-components';

import HomeContainer from '../components/HomeContainer';
import Title from '../components/Title';
import TitleIcon from "../assets/icons/about.svg";
import { useHotspotUserInfo } from '../utils/useHotspotUserInfo';
import Popup from '../components/Popup';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

const Text = styled.p`
color:${props => props.theme.colors.primary};
line-height:24px;
font-weight:200;;
font-size:1rem;
@media screen and (min-width: 768px) {
  font-size:1.25rem;
  line-height:30px;
}`

const Bold=styled.p`
color:${props => props.theme.colors.primary};
line-height:24px;
font-weight:600;
font-size:1rem;
@media screen and (min-width: 768px) {
  font-size:1.25rem;
  line-height:30px;
}`

const Container=styled.div`
color:${props => props.theme.colors.primary};
@media screen and (max-width: 768px) {
  height: 45vh; // Set the desired height
  overflow: auto;
}
height: 60vh; // Set the desired height
overflow: auto;
`
function PrivacyPolicy() {
  const { showPopupUser } = useHotspotUserInfo();
  const { t } = useTranslation();

  return (
    <HomeContainer >
      <Title icon={TitleIcon} text="Privacy.policy" />
    <Container>
    <Trans i18nKey="privacyPolicy" components={{ strong: <strong />, br: <br />,li:<li/> }} />

     
     
      </Container> 
      {showPopupUser && <Popup />}
    </HomeContainer>
  );
}

export default PrivacyPolicy;

