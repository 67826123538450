import React, { createContext, useState } from 'react';

const UrlParamsContext = createContext();

export const UrlParamsProvider = ({ children }) => {
    const [urlParams, setUrlParams] = useState({});

    return (
        <UrlParamsContext.Provider value={{ urlParams, setUrlParams }}>
            {children}
        </UrlParamsContext.Provider>
    );
};

export default UrlParamsContext;