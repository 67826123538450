import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import styled from 'styled-components';
import HomeContainer from '../components/HomeContainer';
import Title from '../components/Title';
import BusIcon from "../assets/icons/bus.svg";
import ShowMap from "../components/ShowMap";
import MapPopup from "../components/MapPopup";
import busMap from "../assets/images/busmap.png";
import busBackground from "../assets/icons/busbackground.svg";
import { useTranslation } from 'react-i18next';
import { useHotspotUserInfo } from '../utils/useHotspotUserInfo';
import Popup from '../components/Popup';
import busSettings from '../services/busService';
import DOMPurify from 'dompurify';

const ContactSpan = styled.div`
  font-weight: 600;
  color: ${props => props.theme.colors.primary};
`;
const Content = styled.div`
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 200;
  background-image: url('${busBackground}');
  background-repeat: no-repeat;
  background-position: center;
  @media (min-width: 768px) {
    justify-content: flex-start;
    margin-right: 2rem;
  }
`;

const Text = styled.div`
  font-size: 1rem;
  color: ${props => props.theme.colors.primary};
  line-height: 24px;
  padding-bottom: 1rem;
  @media (min-width: 768px) {
    padding-bottom: 3rem;
    font-size: 1.5rem;
  }
`;

const Contact = styled.div`
  font-size: 1rem;
  color: ${props => props.theme.colors.primary};
  line-height: 24px;
  padding-bottom: 1rem;
  @media (min-width: 768px) {
    font-size: 1.5rem;
    padding-bottom: 3rem;
  }
`;

const Link = styled.a`
  font-size: 1rem;
  color: ${props => props.theme.colors.primary};
  line-height: 24px;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const MapContent = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;

const MapImage = styled.img`
  @media (max-width: 768px) {
    display: none;
  }
  height: 61.5vh;
  width: 30%;
`;

const Flex = styled.div`
  display: flex;
`;

function Bus() {
  const { showPopupUser } = useHotspotUserInfo();
  const [showMapContent, setShowMapContent] = useState(false);
  const { t } = useTranslation();
  const [htmlContent, setHtmlContent] = useState('');

  // Fetch content from the backend
  const fetchContent = async (language) => {
    try {
      const response = await busSettings.get(`bus_text_${language}`);
      const sanitizedContent = DOMPurify.sanitize(response.data.description.html_content);
      setHtmlContent(sanitizedContent);
    } catch (error) {
      console.error('Error fetching content:', error);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchContent(i18n.language);

    // Handle language changes
    const handleLanguageChange = (lng) => {
      fetchContent(lng);
    };

    i18n.on('languageChanged', handleLanguageChange);

    // Cleanup listener on unmount
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, []);

  const handleToggleMapContent = () => {
    setShowMapContent(!showMapContent);
  };

  return (
    <HomeContainer>
      <Flex>
        <div>
          <Title icon={BusIcon} text="Bus" />
          <Content>
            {!showMapContent ? (
              <>
                <Text dangerouslySetInnerHTML={{ __html: htmlContent }} />
              </>
            ) : (
              ""
            )}
            <MapContent onClick={handleToggleMapContent}>
              {showMapContent ? (
                <MapPopup imageUrl={busMap} onClose={handleToggleMapContent} />
              ) : (
                <ShowMap text={t('howToFindBusStop')} imageUrl="path/to/taxi-image.jpg" />
              )}
            </MapContent>
          </Content>
        </div>
        <MapImage src={busMap} />
      </Flex>
      {showPopupUser && <Popup />}
    </HomeContainer>
  );
}

export default Bus;
