import axios from 'axios';
import { LIST_TAXI_ROUTES_ENDPOINT } from './endpoints';

export const listTaxiRoutes = async (language) => {
  try {
    const response = await axios.get(LIST_TAXI_ROUTES_ENDPOINT, {
      headers: {
        'Accept-Language': language, 
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching taxi routes:', error);
    throw error;
  }
};

