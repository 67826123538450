import React, { useContext } from 'react';
import ThreeOptionsComponent from '../components/Selection';
import CustomInput from '../components/Input';
import CustomDropdown from '../components/Dropdown';
import PhoneNumber from '../components/PhoneNumber';
import Checkbox from '../components/Checkbox';
import Button from '../components/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormSteps from './FormSteps';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { registerHotspotUser } from '../services/hotspotUserService';
import i18n from "../i18n";
import UrlParamsContext from "../contexts/URLparamsContext";
import { UserTypeContext } from '../contexts/UserTypeContext';
import { useSession } from '../contexts/SessionContext'; 



const Mobile = styled.div`
@media screen and (min-width: 768px) {
  display:none;
}`

const Divider = styled.div`

@media screen and (min-width: 768px) {
  flex:1;
  display:flex;
flex-direction:column;
justify-content:space-between;
}
`



const RegisterForm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { urlParams } = useContext(UrlParamsContext);
  const { setUserType } = useContext(UserTypeContext);
  const { updateSession } = useSession();




  const genderOptions = {
    'male': 1,
    'female': 2,
    'other': 3,
  };
  
  const ageRangeOptions = {
    '0-16': 1,
    '17-26': 2,
    '27-36': 3,
    '37-46': 4,
    '47-56': 5,
    '60+': 6,
  };
  
  const selectedOptionOptions = {
    'arrival': 1,
    'departure': 2,
    'visitor': 3,
  };
  


const mapSelectedOptionToUserType = (selectedOption) => {
  switch (selectedOption) {
    case 'arrival':
      return 'landing';
    case 'departure':
      return 'departing';
    case 'visitor':
      return 'visitor';
    default:
      return null;
  }
};


  
  const mapGenderToID = (gender) => genderOptions[gender.toLowerCase()];
  const mapAgeRangeToID = (ageRange) => ageRangeOptions[ageRange];
  const mapSelectedOptionToID = (selectedOption) => selectedOptionOptions[selectedOption.toLowerCase()];
  
  const validationSchema = Yup.object().shape({
    selectedOption: Yup.string().required(),
    email: Yup.string().email(t('invalidEmail')).required(t('required')),
    gender: Yup.string().required(t('required')),
    age: Yup.string().required(t('required')),
    phone: Yup.string().test(
      'isValid',
      t('invalidPhone'),
      (value) => {
        const isMkNumber = value.startsWith('+389'); 
  
        if (isMkNumber) {
          return Yup.string().min(11).isValidSync(value);
        } else {
          return Yup.string().min(8).isValidSync(value);
        }
      }
    ).required(t('required')),
    termsAndConditions: Yup.boolean().oneOf([true], 'Must accept Terms and Conditions'),
  });


  const formik = useFormik({
    initialValues: {
      selectedOption: 'arrival',
      email: '',
      gender: '',
      age: '',
      phone: '',
      termsAndConditions: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      const requestData = {
        email: values.email,
        user_type_id: mapSelectedOptionToID(values.selectedOption),
        user_age_range_id: mapAgeRangeToID(values.age),
        user_gender_id: mapGenderToID(values.gender),
        phone_number: values.phone,
        language: i18n.language,
        ...urlParams,
      };
    
      try {
       
        const response = await registerHotspotUser(requestData);
      
        const remainingTime = response.data.data.data_usage_remaining; 
        setUserType(mapSelectedOptionToUserType(values.selectedOption));
        updateSession(response.data.data.email);

    navigate('/welcome', { state: { showPopup: true, remainingTime } });
  } catch (error) {
       
        console.error('Error during registration:', error);
      }
    },
    
  });



  return (
    <Divider>
      <Mobile>
        <FormSteps second />

      </Mobile>
      <form onSubmit={formik.handleSubmit}>
        <ThreeOptionsComponent
          options={['arrival', 'departure','visitor']}
          selectedOption={formik.values.selectedOption}
          onSelectOption={(option) => formik.setFieldValue('selectedOption', option)}
        />

        <CustomInput
          placeholder={t('emailPlaceholder')}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && formik.errors.email}
        />


        <CustomDropdown
          options={['male', 'female', 'other']}
          name="gender"
          placeholder={t('genderPlaceholder')}

          value={formik.values.gender}
          onChange={(value) => formik.setFieldValue('gender', value)}
          onBlur={formik.handleBlur}
          error={formik.touched.gender && formik.errors.gender}
        />

        <CustomDropdown
          options={['0-16', '17-26', '27-36', '37-46', '47-56', '60+']}
          placeholder={t('agePlaceholder')}

          name="age"
          value={formik.values.age}
          onChange={(value) => formik.setFieldValue('age', value)}

          onBlur={formik.handleBlur}
          error={formik.touched.age && formik.errors.age}
        />

        <PhoneNumber
          value={formik.values.phone}
          name="phone"
          placeholder={t("phonePlaceholder")}
          onChange={(value) => {
            formik.setFieldValue('phone', value);
            if (formik.touched.phone) {
              formik.setFieldTouched('phone');
              formik.validateField('phone');
            }
          }}
          
          onBlur={formik.handleBlur}  
        error={formik.touched.phone && formik.errors.phone}
        />
    

        <Checkbox
          checked={formik.values.termsAndConditions}
          onChange={() => formik.setFieldValue('termsAndConditions', !formik.values.termsAndConditions)}
          label={t( "termsAndConditionsLabel")}
          language={i18n.language}
        />

          <Button disabled={!formik.isValid} onClick={formik.handleSubmit} >{t('submitButton')}</Button>

      </form>
    </Divider>
  );
};

export default RegisterForm;