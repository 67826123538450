import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationsInAlbanian from './locales/al.json';
import translationsInEnglish from './locales/en.json';
import translationsInMacedonian from './locales/mk.json';


const resources = {
  en: {
    translation: translationsInEnglish,
  },
  mk: {
    translation: translationsInMacedonian,
  },
  al: {
    translation: translationsInAlbanian,
  },
};


if (!i18n.isInitialized) {
  i18n
    .use(initReactI18next) 
    .init({
      resources, 
      lng: 'en', 
      debug: true,
      fallbackLng: 'en', 
      interpolation: {
        escapeValue: false,
      },
      ns: 'translation', 
      defaultNS: 'translation',
    });
}

export default i18n;
