import React, { useRef } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import styled from 'styled-components';

const StyledPhoneInput = styled(PhoneInput)`
  --react-international-phone-height: 36px;
  --react-international-phone-background-color: white;
  --react-international-phone-text-color: ${props => props.theme.colors.primary};
  --react-international-phone-font-size: 1rem;
  --react-international-phone-border-radius: 0px;
  --react-international-phone-border-color: none;
  --react-international-phone-width: 280px !important;
`;

const PhoneNumberDiv = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
 
`;

const PhoneNumberLabel = styled.span`
  display: ${props => (props.$shouldDisplay ? 'block' : 'none')};
  position: absolute;
  margin-left: 7rem;
  color: ${props => props.theme.colors.primary}50;
 
`;

const ErrorMessage=styled.div`
color:rgba(203, 130, 130, 0.5);`

const PhoneNumber = ({ value, onChange, onBlur, country, placeholder, error }) => {
  const inputRef = useRef(null); 
  const combinedPlaceholder = `${country ? `+${country}` : ''} ${placeholder}`;
  const shouldDisplayPlaceholder = value.length <= 4;

  const handleLabelClick = () => {
    if (inputRef.current) {
      inputRef.current.focus(); 
    }
  };

  return (
    <>
      <PhoneNumberDiv>
        <PhoneNumberLabel 
          $shouldDisplay={shouldDisplayPlaceholder} 
          onClick={handleLabelClick} 
        >
          {combinedPlaceholder}
        </PhoneNumberLabel>
        <StyledPhoneInput
          ref={inputRef} 
          defaultCountry="mk"
          placeholder=""
          name="phone"
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
      </PhoneNumberDiv>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default PhoneNumber;