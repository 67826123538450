import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom:1rem;
`;

const Icon = styled.img`
  width: 20px; 
  height: 20px;
  margin-right: 8px; 
  @media screen and (min-width: 768px) {
    width:40px;
    height:40px;
  }

`;

const Text = styled.div`
  font-size: 1.5rem;
  color: ${props => props.theme.colors.primary}; 
  font-weight:700;
  @media screen and (min-width: 768px) {
    font-size:2.5rem;
  }
`;

const Title = ({ icon, text }) => {
  const { t } = useTranslation();

  return (
    <TitleContainer>
      {icon && <Icon src={icon} alt="Icon" />}
      <Text>{t(text)}</Text>
    </TitleContainer>
  );
};

export default Title;
