import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./styles/GlobalStyles.css";
import { ThemeProvider } from 'styled-components';
import { theme } from "./styles/theme";
import { UrlParamsProvider } from '../src/contexts/URLparamsContext';
import { DeviceProvider } from "./contexts/DeviceContext";
import { UserTypeProvider } from "./contexts/UserTypeContext";
import { SessionProvider } from './contexts/SessionContext';

//Pages
import Home from "./pages/Home";
import About from "./pages/About"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import Login from "./pages/Login";
import Welcome from "./pages/Welcome";
import Bus from "./pages/Bus";
import Taxi from "./pages/Taxi";
import CarPark from "./pages/CarPark";
import RentACar from "./pages/Rent-a-car";
import TermsAndConditions from "./pages/TermsAndConditions";
import NotFound from "./pages/NotFound";






const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  }, {
    path: "/welcome",
    element: <Welcome />,
  },
  {
    path: "/login",
    element: <Login />,
  }, {
    path: "/bus",
    element: <Bus />,
  }, {
    path: "/rent-a-car",
    element: <RentACar />,
  }, {
    path: "/taxi",
    element: <Taxi />,
  }, {
    path: "/car-park",
    element: <CarPark />,
  },
  {
    path:"/terms-and-conditions/:lang",
    element: <TermsAndConditions />,
  },
  {
    path: "*",
    element: <NotFound />,
  },

]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <UserTypeProvider>
  <DeviceProvider>
    <UrlParamsProvider>
    <SessionProvider>
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
  </SessionProvider>
  </UrlParamsProvider>
  </DeviceProvider>  
  </UserTypeProvider>
);


