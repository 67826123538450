import { sprintf } from 'sprintf-js';

const BASE_API_ENDPOINT = "https://api.skpairport.mk/api/v1";


//Rent-a-car
export const RENT_A_CAR_LIST_API_ENDPOINT = `${BASE_API_ENDPOINT}/rent-a-car/list`;
export const GET_OPERATOR_DETAILS_ENDPOINT = `${BASE_API_ENDPOINT}/rent-a-car/get/%(id)s`;

//Taxi
export const LIST_TAXI_ROUTES_ENDPOINT = `${BASE_API_ENDPOINT}/taxi/route/list`;

//Parking
export const LIST_PARKING_PRICES_ENDPOINT = `${BASE_API_ENDPOINT}/car-park/parking/list`;
export const LIST_HUG_AND_FLY_PRICES_ENDPOINT = `${BASE_API_ENDPOINT}/car-park/hug-and-fly/list`;

//HotSpot User
export const HOTSPOT_USER_ENDPOINT=`${BASE_API_ENDPOINT}/hotspot-user/store`;
export const HOTSPOT_USER_REMAINING_ENDPOINT =`${BASE_API_ENDPOINT}/hotspot-user/me?mac_address=%(mac)s`;

//Maps
export const MAP_ENDPOINT =`${BASE_API_ENDPOINT}/global-map/get/type/%(type_name)s`;


//Settings
export const DASHBOARD_SETTINGS_GET_TEXT=`${BASE_API_ENDPOINT}/hotspot-settings/%(key)s`;

//Buss
export const BUS_GET_TEXT=`${BASE_API_ENDPOINT}/hotspot-settings/%(key)s`;
