export const theme = {
    colors: {
      primary: '#031F73',
      secondary: '#AEB3B7',
      dark:'#304050',
      error: '#CB8282',
    },
    fontsWeight: {
      thin:"100",
      light:"300",
      regular:"400",
      bold:"700",
    },
    fontSize: {
      16: '1rem',
      24: '1.5rem',
      40: '2.5rem',
    },
    spaces:{
      "extra-small": 0.5,
      small: 0.75,
      medium: 1,
      large: 2,
      "extra-large": 4,
    },
    breakpoints: {
      small: '576px',
      medium: '768px',
      large: '992px',
      xlarge: '1200px',
    },
    borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    centerContent: () => {
      return `
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
      `;
    },
  };
  