import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const CustomSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  position: relative;
  font-size: 1rem;
`;

const CustomSelectField = styled.div`
  position: relative;
  border: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary};
  padding: 12px;
  outline: none;
  color: ${(props) =>
    props.$hasValue
      ? props.theme.colors.primary
      : props.theme.colors.primary + '50'};
  cursor: pointer;
  font-size: 1rem;
  @media (min-width: 768px) {
    font-size:1rem;
  }
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border: 5px solid transparent;
    border-top-color: ${(props) => props.theme.colors.primary};
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border: 5px solid transparent;
    border-top-color: ${(props) => props.theme.colors.primary};
  }

  &:focus {
    border-bottom: 2px solid ${(props) => props.theme.colors.primary};
  }
`;


const OptionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: ${props => (props.$visible ? 'block' : 'none')};
  @media (min-width: 768px) {
    font-size:1rem;
  }
`;

const Option = styled.div`
  padding: 10px 13px;
  cursor: pointer;
  border-top: 1px solid ${props => props.theme.colors.primary};

  &:active {
    color: ${props => props.theme.colors.primary};
  }

  color: ${props => props.theme.colors.primary};
`;

const ErrorMessage=styled.div`
color:rgba(203, 130, 130, 0.5);`

const CustomDropdown = ({ name, options, placeholder, value, onChange, onBlur, error }) => {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const dropdownRef = useRef(null);
  const { t } = useTranslation();


  const handleSelectClick = () => {
    setOptionsVisible(!optionsVisible);
  };

  const handleOptionClick = (selectedValue) => {
    onChange(selectedValue);
    setOptionsVisible(false);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOptionsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>
      <CustomSelectContainer ref={dropdownRef}>
        <CustomSelectField onClick={handleSelectClick} onBlur={onBlur} $hasValue={!!value}>
          {value ? t(value) : placeholder}
        </CustomSelectField>
        {error && <div style={{ color: 'red' }}>{error}</div>}
        <OptionsContainer $visible={optionsVisible}>
          {options.map((option) => (
            <Option key={option} onClick={() => handleOptionClick(option)}>
              {t(option)}
            </Option>
          ))}
        </OptionsContainer>
      </CustomSelectContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default React.memo(CustomDropdown);

