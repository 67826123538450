import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  width: 24px; 
  height: 24px; 
  
  @media (min-width: 768px) {
    width: 40px; 
    height: 40px; 
  }

  @media screen and (min-width: 1200px {
    width: 55px; 
    height: 55px; 
  }
`;

const IconImg = styled.img`
width:100%;`



const Icon = ({ src, alt }) => {
  return (
    <IconWrapper>
      <IconImg src={src} alt={alt} />
    </IconWrapper>
  );
};

export default Icon;
