import React , {useContext} from 'react';
import styled from 'styled-components';
import Footer from './Footer';
import Header from './Header';
import banner from '../assets/images/electronic-devices-balancing-concept.jpg';
import { UserTypeContext } from '../contexts/UserTypeContext';
import { DeviceContext } from '../contexts/DeviceContext';

const StyledHomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  height: 80vh;
  box-sizing: border-box;
  margin: 2.5rem 1.5rem 2.5rem 1.5rem;

  @media screen and (max-width: 768px) {
    justify-content: space-between; 
  }

  @media screen and (min-width: 768px) {
    margin: 3.5rem 4rem 4.5rem 4rem;
  }
`;

const BannerImage = styled.img`
 height: 30vh;
`;

const Content = styled.div`
@media screen and (min-width: 768px) {
  display:flex;
}
`

const BannerContainer = styled.div`
@media screen and (min-width: 768px) {
  display:flex;
  flex-direction:column;

  margin-left:1rem;
  margin-bottom:1rem;
  gap:10px;
}

display:none;
`

function HomeContainer({ children }) {
  const { isMobile } = useContext(DeviceContext);
  const { userType } = useContext(UserTypeContext);

  const iframeUrls = {
    mobile: {
      landing: '',
      departing: '',
      visitor: '',
    },
    desktop: {
      landing: 'https://ads.skpairport.mk/www/delivery/afr.php?zoneid=3',
      departing: 'https://ads.skpairport.mk/www/delivery/afr.php?zoneid=4',
      visitor: 'https://ads.skpairport.mk/www/delivery/afr.php?zoneid=5',
    },
  };

  const iframeUrls2 = {
    mobile: {
      landing: '',
      departing: '',
      visitor: '',
    },
    desktop: {
      landing: 'https://ads.skpairport.mk/www/delivery/afr.php?zoneid=6',
      departing: 'https://ads.skpairport.mk/www/delivery/afr.php?zoneid=7',
      visitor: 'https://ads.skpairport.mk/www/delivery/afr.php?zoneid=8',
    },
  };

  const renderIframe = () => {
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const iframeUrl = iframeUrls[deviceType][userType];
    return <iframe src={iframeUrl} style={{ width: '100%', height: '230px',border:"none",scrolling:"no" }} title="Ad Banner" />;
  };

  const renderIframe2 = () => {
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const iframeUrl = iframeUrls2[deviceType][userType];
    return <iframe src={iframeUrl} style={{ width: '100%', height: '230px',border:"none",scrolling:"no" }} title="Ad Banner" />;
  };

  return (
    <StyledHomeContainer>
      <div>
        <Header />
        <Content>
          <div style={{ flex: 1 }}>{children}</div>
          <BannerContainer>
            {renderIframe()} 
            {renderIframe2()}
          </BannerContainer>
        </Content>
      </div>
      <Footer />
    </StyledHomeContainer>
  );
}

export default HomeContainer;
