import React, { useState,useEffect ,useContext} from 'react';
import styled from 'styled-components';
import { theme } from '../styles/theme';
import LanguageSwitcherDropdown from './LanguageSwitcherDropdown';
import Menu from './Menu';
import banner from '../assets/images/baner.png';
import { UserTypeContext } from '../contexts/UserTypeContext';
import { DeviceContext } from '../contexts/DeviceContext';

const StyledFooter = styled.div`
display: flex;
flex-direction: column;
`;

const Block = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;

align-items:center;

@media screen and (min-width: 768px) {
  justify-content:flex-end;
}
`


const LanguageSwitcher = styled(LanguageSwitcherDropdown)`
  @media screen and (min-width: 768px) {
    display: none!important;
  }
`;

const BannerImage = styled.img`
width: 100%;
height: 12vh;
margin-bottom:2rem;
box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`;



function Footer() {
  const { isMobile } = useContext(DeviceContext);
  const { userType } = useContext(UserTypeContext);

  const iframeUrls = {
    mobile: {
      landing: 'https://ads.skpairport.mk/www/delivery/afr.php?zoneid=14',
      departing: 'https://ads.skpairport.mk/www/delivery/afr.php?zoneid=15',
      visitor: 'https://ads.skpairport.mk/www/delivery/afr.php?zoneid=16',
    },
    desktop: {
      landing: '',
      departing: '',
      visitor: '',
    },
  };

  const renderIframe = () => {
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const iframeUrl = iframeUrls[deviceType][userType];
    return <iframe src={iframeUrl} className='mobile-only' style={{ width: '100%', height: '125px',border:"none",marginBottom:"1rem",scrolling:"no"}} title="Ad Banner" />;
  };
  return (
    <StyledFooter>
      {renderIframe()}
      <Block>
        <Menu />
        <div className="mobile-only" ><LanguageSwitcher /></div>
      </Block>
    </StyledFooter>
  );
}

export default Footer;
