import React from 'react';
import styled from 'styled-components';
import close from "../assets/icons/close.svg";




const CloseButton = styled.span`
  display:flex;
  align-items:center;
  cursor: pointer;
  justify-content:flex-end;
  z-index: 1; 
`;

const Icon = styled.img`
margin-left:0.5rem;
`

const Img = styled.img`
max-width:100%;
max-height:100%;
border:1px solid black;
`

const Text = styled.div`
color: ${props => props.theme.colors.primary}70;`

const Popup = ({ imageUrl, onClose }) => {
  return (
    
      <div>
        <CloseButton onClick={onClose}>
          <Text>
            Close map
          </Text>
          <Icon src={close} />
        </CloseButton>
        <Img src={imageUrl} alt="Map" />
      </div>
 
  );
};

export default Popup;
