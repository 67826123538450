import React, { useEffect, useState } from "react";
import styled from "styled-components";
import i18n from "../i18n";

const Dropdown = styled.select`
  padding: 8px;
  font-size: 20px;
  font-weight: 700;
  border: none;
  color: ${props => props.theme.colors.primary};
`;

const Option = styled.option``;

const LanguageSwitcherDropdown = () => {
  const languages = ["en", "mk", "al"];
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  useEffect(() => {
    // Update the selected language when the language changes
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language);
    setSelectedLanguage(language);
    
  };

  return (
    <Dropdown
      value={selectedLanguage}
      onChange={(e) => handleLanguageChange(e.target.value)}
    >
      {languages.map((language) => (
        <Option key={language} value={language}>
          {language.toUpperCase()}
        </Option>
      ))}
    </Dropdown>
  );
};

export default LanguageSwitcherDropdown;
