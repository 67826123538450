import React, { useState,useEffect ,useContext} from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { UserTypeContext } from '../contexts/UserTypeContext';
import { DeviceContext } from '../contexts/DeviceContext';
import { useHotspotUserInfo } from '../utils/useHotspotUserInfo';

import HomeContainer from '../components/HomeContainer';
import Title from '../components/Title';
import Card from '../components/Card';
import ConectedPopup from '../components/ConectedPopup'; 
import Popup from '../components/Popup';

import banner from '../assets/images/baner.png';
import busImage from "../assets/images/busImg.jpg";
import taxiImage from "../assets/images/taxi.jpg";
import renACarImage from "../assets/images/rentACarImg.jpg";
import carParkImage from "../assets/images/parkImg.jpg";





const BannerImage = styled.img`
  width: 100%;
  height: 12vh;
  margin-bottom:1rem;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`;

const CardSection = styled.div`
display:flex;
flex-direction:row;
overflow-x: auto;
margin-bottom:1rem;
height:29vh;

@media (min-width: 768px) {
    height: 61.5vh;
    width:69vw;
}
`


function Welcome() {
  const { showPopupUser } = useHotspotUserInfo();
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(location.state?.showPopup || false);
  const remainingTime = location.state?.remainingTime;
  const { isMobile } = useContext(DeviceContext);
  const { userType } = useContext(UserTypeContext);

  

  useEffect(() => {
    const fromRegistration = location.state?.fromRegistration;
    const popupShown = localStorage.getItem('popupShown') === 'true';
   
    

    if (fromRegistration && !popupShown && remainingTime != 0) {
      setShowPopup(true);
      localStorage.setItem('popupShown', 'true'); 
    }
  }, [location.state]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const iframeUrls = {
    mobile: {
      landing: 'https://ads.skpairport.mk/www/delivery/afr.php?zoneid=11',
      departing: 'https://ads.skpairport.mk/www/delivery/afr.php?zoneid=12',
      visitor: 'https://ads.skpairport.mk/www/delivery/afr.php?zoneid=12',
    },
    desktop: {
      landing: '',
      departing: '',
      visitor: '',
    },
  };

  const renderIframe = () => {
    const deviceType = isMobile ? 'mobile' : 'desktop';
    const iframeUrl = iframeUrls[deviceType][userType];
    return <iframe src={iframeUrl} className='mobile-only' style={{ width: '100%',border:"none", height: '125px',overflow:"hidden",marginBottom:"1rem" ,scrolling:"no"}} title="Ad Banner" />;
  
  };

  return (
    
    <HomeContainer>
            {showPopup && remainingTime != 0 && <ConectedPopup time={remainingTime} onClose={handleClosePopup} />}

      <div className='mobile-only'><Title icon={null} text="Welcome" /></div>

      {renderIframe()} 
      <CardSection>
      <Card text="carPark" backgroundImage={carParkImage} to="/car-park" />
        <Card text="Bus" backgroundImage={busImage} to="/bus" />
        <Card text="Taxi" backgroundImage={taxiImage} to="/taxi" />
        <Card text="rentACar" backgroundImage={renACarImage} to="/rent-a-car" />

      </CardSection>
    
      {showPopupUser  && <Popup />}
    </HomeContainer>
  );
}

export default Welcome;


