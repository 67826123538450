import React from 'react';
import styled, { css } from 'styled-components';

const buttonStyle = css`
  font-size: 1.5rem;
  padding: 1rem 1.5rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  font-weight: 700;
  background-color: ${props => props.theme.colors.primary};

  &:hover {
    background-color: ${props => props.theme.colors.primary}90;
    
  }

  @media screen and (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const StyledButton = styled.button`
  ${buttonStyle}
  color: #fff;
  width:100%;


  ${props => props.disabled && css`
  background-color: ${props => props.theme.colors.primary}20;
    cursor: not-allowed;
  `}
`;

const Button = ({ disabled = false, onClick, children }) => {
  return (
    <StyledButton type="button" disabled={disabled} onClick={onClick}>
      {children}
    </StyledButton>
  );
};

export default Button;
