import axios from 'axios';
import { RENT_A_CAR_LIST_API_ENDPOINT, GET_OPERATOR_DETAILS_ENDPOINT } from './endpoints';
import { sprintf } from 'sprintf-js';

export const listCarOperators = async () => {
  try {
    const response = await axios.get(RENT_A_CAR_LIST_API_ENDPOINT);
    return response.data;
  } catch (error) {
    console.error('Error fetching car operators:', error);
    throw error;
  }
};

export const getOperatorDetails = async (id) => {
  try {
    const endpoint = sprintf(GET_OPERATOR_DETAILS_ENDPOINT, { id });
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching operator details:', error);
    throw error;
  }
};
