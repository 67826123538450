import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import HomeIcon from "../assets/icons/home.svg"
import { useTranslation } from 'react-i18next';


const MenuContainer = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
text-decoration:underline;
font-size:1.25rem;

 

  @media (max-width: 768px) {
    justify-content: flex-end;
    text-decoration:none;
  }
`;

const MenuItemMiddle = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.primary};
  padding-right: 0.5rem;
  padding-left:0.5rem;
  margin-right:0.5rem;
  margin-left:0.5rem;
  border-right: 1px solid ${props => props.theme.colors.primary};
  border-left: 1px solid ${props => props.theme.colors.primary};
  font-weight:200;;
  line-height: 0.90;
  @media (min-width: 768px) {
    border-left: none;
  }
  
`;

const MenuItem = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.primary};
  font-weight:200;;
`;

const Menu = () => {
  const { t } = useTranslation();
  return (
    <MenuContainer>
      <MenuItem to="/welcome" className="mobile-only"><img src={HomeIcon} />{t("home")}</MenuItem>
      <MenuItemMiddle to="/about">{t("about")}</MenuItemMiddle>
      <MenuItem to="/privacy-policy">{t("privacy.policy")}</MenuItem>
    </MenuContainer>
  );
};

export default Menu;
