import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { listCarOperators, getOperatorDetails } from '../services/rentACarService';
import InfoText from './InfoText';

const TableContainer = styled.div`
  margin: 0 auto;
  overflow:auto;
  height:51vh;
  @media screen and (min-width: 767px) {
    height:52vh;
  }`
  ;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #ccc;

  &:last-child {
    border-bottom: none;
  }
`;

const CompanyName = styled.div`
  flex: 1;
  color: ${props => props.theme.colors.primary};

`;

const ShowMoreButton = styled.button`
  background: none;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  text-decoration: underline;
  color: ${props => props.theme.colors.primary}50;
  @media (min-width: 768px) {
    font-size:1rem;
  }
`;

const PopupOverlay = styled.div`
  display: ${(props) => (props.$show ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => props.theme.colors.primary}50;
  justify-content: center;
  align-items: center;
`;

const PopupContent = styled.div`
  background-color:${props => props.theme.colors.primary};
  color: #fff;
  padding: 5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
`;

const Info = styled.div`
align-items:center;
justify-content:center;
display:flex;
flex-direction:column;`

const MoreInfoIcon = styled.span`
  margin-left: 5px;
`;

const Logo=styled.img`
max-width:20%`

const RentACarTable = () => {
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    listCarOperators().then(response => {
      setOperators(response.data);
    }).catch(error => {
      console.error('Error fetching car operators:', error);
    });
  }, []);

  const handleShowMore = (operatorId) => {
    getOperatorDetails(operatorId).then(response => {
  
      setSelectedOperator(response.data);
      setShowPopup(true);
    }).catch(error => {
      console.error('Error fetching operator details:', error);
    });
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
    <TableContainer>
      {operators.map((operator) => (
        <TableRow key={operator.id}>
          <CompanyName>{operator.name.toUpperCase()}</CompanyName>
          <ShowMoreButton onClick={() => handleShowMore(operator.id)}>
            {t('moreInfo')}<MoreInfoIcon>&gt;</MoreInfoIcon>
          </ShowMoreButton>
        </TableRow>
      ))}

      {showPopup && selectedOperator && (
        <PopupOverlay $show={showPopup}>
          <PopupContent>
            <CloseButton onClick={handleClosePopup}>X</CloseButton>
            <Info>
              {selectedOperator.company_logo && (
                <Logo src={selectedOperator.company_logo} alt="Company Logo" />
              )}              <br />
              <h2>{selectedOperator.name.toUpperCase()}</h2>
              <br />
              {selectedOperator.contacts.map((contact, index) => (
                <div key={index}>
                  <p> {contact.phone_number}</p>

                </div>
              ))}
              <br />
              <p style={{ color: '#fff', fontWeight: '600' }}> {selectedOperator.work_from} - {selectedOperator.work_to}</p>
              <br />
              {selectedOperator.contacts.map((contact, index) => (
                <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: "center" }}>
                  <p> <a style={{ color: '#fff', fontWeight: '600' }} href={contact.web_site} target="_blank" rel="noopener noreferrer">{contact.web_site}</a></p>
                  <a style={{ color: '#fff', textDecoration: "none" }} href={`mailto:${contact.email}`}>
                    {contact.email}
                  </a>
                  <br />
                </div>
              ))}


            </Info>
          </PopupContent>
        </PopupOverlay>
      )}
    </TableContainer>
    <InfoText keyName="rent_a_car"/>
        
       </>
  );
};
export default RentACarTable;
