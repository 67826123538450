import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import dashboardSettings from '../services/settingsService';
import { useTranslation } from 'react-i18next';

const Info = styled.div`
  color: ${(props) => props.theme.colors.primary};
  margin-top:0.5rem;
  margin-bottom:0.5rem;
`;

const InfoText = ({ keyName }) => {
  const [infoText, setInfoText] = useState('');
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const language = i18n.language; 
        const response = await dashboardSettings.get(`${keyName}_text_${language}`);
        const data = response.data;
       
        if (data.value === '1') {
            setInfoText(data.description);
        } else {
            setInfoText(''); 
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setInfoText(''); 
      }
    };

    fetchData();
  }, [keyName, i18n.language]); 


  return infoText && <Info>{infoText}</Info>;
};

export default InfoText;
