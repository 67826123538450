import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const OptionContainer = styled.div`
  width: 100%;
  background-color: ${(props) => (props.$active ? props.theme.colors.primary : props.theme.colors.primary + '20')};
  color: ${(props) => (props.$active ? 'white' : props.theme.colors.primary + '90')};
  padding: 2px 0;
  cursor: pointer;
  text-align: center;
  .thirdOption {
    width:100%;
  }
  &.flexOption {
    flex: 0.49; /* Use flex: 0.5 to make each option take up half the width */
  }
  @media (min-width: 768px) {
    font-size:1rem;
  }
  ${(props) =>
    props.$active &&
    css`
      box-shadow: 0px 4px 4px 0px #00000040;
      background-color: ${props.theme.colors.primary};
      color: white;
    `}
`;


const ThreeOptionsComponent = ({ options, selectedOption, onSelectOption }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <RowContainer>
        {options.map((option, index) => (
          (index === 0 || index === 1) && (
            <OptionContainer className='flexOption'
              key={option}
              $active={selectedOption === option}
              onClick={() => onSelectOption(option)}
            >
              {t(option)}
            </OptionContainer>
          )
        ))}
      </RowContainer>
      <RowContainer>
        {options.map((option, index) => (
          index === 2 && (
            <OptionContainer className='thirdOption'
              key={option}
              $active={selectedOption === option}
              onClick={() => onSelectOption(option)}
            >
              {t(option)}
            </OptionContainer>
          )
        ))}
      </RowContainer>
    </Container>
  );
};

export default ThreeOptionsComponent;