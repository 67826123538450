import axios from 'axios';
import { LIST_PARKING_PRICES_ENDPOINT, LIST_HUG_AND_FLY_PRICES_ENDPOINT } from './endpoints';

export const listParkingPrices = async (language) => {
  try {
    const response = await axios.get(LIST_PARKING_PRICES_ENDPOINT, {
      headers: {
        'Accept-Language': language, 
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching parking prices:', error);
    throw error;
  }
};

export const listHugAndFlyPrices = async (language) => {
  try {
    const response = await axios.get(LIST_HUG_AND_FLY_PRICES_ENDPOINT, {
      headers: {
        'Accept-Language': language, 
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Hug&Fly prices:', error);
    throw error;
  }
};



