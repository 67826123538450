// MapOption.js
import React, { useState } from 'react';
import styled from 'styled-components';
import Popup from './MapPopup';
import mapIcon from "../assets/icons/map.svg";
import busMap from "../assets/images/busmap.png";

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
margin-bottom:0.5rem;
  position: relative;
`;

const Icon = styled.img`
  margin-right: 0.25rem;
`;

const Text = styled.div`
  margin-right: 10px;
  text-decoration:underline;
  color: ${props => props.theme.colors.primary}90;
`;

const ShowMap = ({ text, imageUrl, icon }) => {
  const [showImage, setShowImage] = useState(false);

  const handleToggleImage = () => {
    setShowImage(!showImage);
  };

  const handleCloseImage = () => {
    setShowImage(false);
  };

  return (
    <Container>
      <Icon src={mapIcon} alt="Icon" />
      <Text onClick={handleToggleImage}>{text}</Text>
      {showImage && <Popup imageUrl={busMap} onClose={handleCloseImage} />}
    </Container>
  );
};

export default ShowMap;
