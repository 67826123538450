// Popup.js
import React , {useState} from 'react';
import styled from 'styled-components';
import disconected from "../assets/icons/disconected.svg";
import { useTranslation } from 'react-i18next';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.primary}70;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure a high z-index for the overlay */
`;

const PopupContent = styled.div`
display:flex;
flex-direction:column;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  position: relative;
  background-color:${props => props.theme.colors.primary};
color:white;
  z-index: 101; 
  padding:2rem;
`;

const CloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  cursor: pointer;
  color: white;
`;

const Title=styled.div`
font-size:1.5rem;
color:white;

text-align: center;

font-style: normal;
font-weight: 700;

letter-spacing: 0.2px;
margin-top:2rem;
margin-bottom:2rem;
`
const Text=styled.div`
font-size:1.25rem;

color: #FFF;
text-align:center;
`

const Img=styled.img`
align-self:center;`



const Popup = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(true);

  const onClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <Overlay>
      <PopupContent>
        <CloseButton onClick={onClose}>X</CloseButton>
        <Img src={disconected} alt="Disconnected Icon"></Img>
        <Title>{t('reached-limit')}</Title>
        <Text>{t('disconnected')}</Text>
      </PopupContent>
    </Overlay>
  );
};

export default Popup;