import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { getInfoForHotspotUser } from '../services/hotspotUserService';
import UrlParamsContext from '../contexts/URLparamsContext'; 

export const useHotspotUserInfo = () => {
  const location = useLocation();
  const { urlParams } = useContext(UrlParamsContext); 
  const [showPopupUser, setShowPopupUser] = useState(false);

  useEffect(() => {
  
    const macAddress = urlParams.ap_mac; 

    if (location.pathname !== '/' && location.pathname !== '/login' && macAddress) {
      getInfoForHotspotUser(macAddress) 
        .then(data => {
        
          if (data.data.data_usage_remaining <= 0) {
            setShowPopupUser(true);
          } else {
            setShowPopupUser(false);
          }
        })
        .catch(error => {
          console.error('Failed to fetch hotspot user info:', error);
        });
    }
  }, [location, urlParams]);

  return { showPopupUser };
};
