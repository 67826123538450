import React from 'react';
import styled from 'styled-components';

import HomeContainer from '../components/HomeContainer';
import Title from '../components/Title';
import TitleIcon from "../assets/icons/about.svg";
import { useHotspotUserInfo } from '../utils/useHotspotUserInfo';
import Popup from '../components/Popup';
import { useTranslation } from 'react-i18next';

const Text = styled.p`
color:${props => props.theme.colors.primary};
line-height:24px;
font-weight:200;;
font-size:1rem;
@media screen and (min-width: 768px) {
  font-size:1.25rem;
  line-height:30px;
}`

const Container=styled.div`
@media screen and (max-width: 768px) {
height:45vh;
}
`



function About() {
  const { showPopupUser } = useHotspotUserInfo();
  const { t } = useTranslation();

  return (
    <HomeContainer >
      <Title icon={TitleIcon} text="About" />
     <Container>
     <Text>{t('about-text')}</Text>
      </Container> 
      {showPopupUser && <Popup />}
    </HomeContainer>
  );
}

export default About;

